import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';

import FormHeader from '@/components/inspections/formats/format-a/headers/FormHeader.vue';
import FormBody from '@/components/inspections/formats/hseq-sso1-f-87/bodies/CriteriaList/FormBody.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'RegistersCreateHseqSso1F87',
	data: () => ({
		validationErrors: [],
		steps: {
			current: 1,
			views: [1],
		},
		isLoadingData: false,
		isLoadingSave: false,
		isLoadingDraft: false,
	}),
	computed: {
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),
		totalQuestions: function () {
			let total = 0;
			if (Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies.forEach((body) => {
					total += body.items.length;
				});
			}
			return total;
		},
		totalItemsApply: function () {
			let total = 0;
			if (this.inspection && Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (['SI', 'NO'].includes(item.value)) {
							total++;
						}
					});
				});
			}
			return total;
		},
		totalItemsResponse: function () {
			let total = 0;
			if (this.inspection && Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (item.value && item.value != '') {
							total++;
						}
					});
				});
			}
			return total;
		},
		optionsAnswer: function () {
			let options = [];
			if (this.inspection && Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies.forEach((body) => {
					body.options_answer.forEach((option) => {
						let exists = false;
						options.forEach((optionItem) => {
							if (optionItem.value == option.value) {
								exists = true;
							}
						});
						if (!exists) {
							options.push({
								label: option.label,
								value: option.value,
								color: option.color,
								total: 0,
							});
						}
					});
				});

				// SET TOTALS
				this.inspection.bodies.forEach((body) => {
					body.items.forEach((item) => {
						options.forEach((optionItem, indexOI) => {
							if (item.value == optionItem.value) {
								options[indexOI].total++;
							}
						});
					});
				});
			}
			return options;
		},
		totalAnswers: function () {
			let total = 0;
			this.optionsAnswer.forEach((option) => {
				total += option.total;
			});
			return total;
		},
		validateObservations: function () {
			let result = true;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (
							item.value &&
							item.value == 'NO' &&
							(!item.observations || item.observations == '')
						) {
							result = false;
						}
					});
				});
			}
			return result;
		},
		completedQuestionary: function () {
			return (
				this.totalItemsResponse == this.totalQuestions &&
				this.totalItemsResponse > 0 &&
				this.validateHeader() &&
				this.validateObservations
			);
		},
		breadcrumbs: function () {
			return [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection
						? textTransform(this.inspection.name)
						: this.$route.params.inspectionId,
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsRegisterList',
						params: { id: this.$route.params.inspectionId },
					},
				},
				{
					text: 'Nuevo registro',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	created() {
		this.initialize();
		$EventBus.$emit('showSubNav', false);
	},
	watch: {
		'steps.current': function (newVal) {
			if (!this.steps.views.includes(newVal)) {
				this.steps.views.push(newVal);
			}
		},
	},
	mounted() {},
	methods: {
		...mapActions('inspection', ['find', 'cleanInspection']),
		...mapActions('register', ['create']),

		textTransform,

		initialize() {
			this.getData();
		},
		getData() {
			this.isLoadingData = true;
			this.find({
				success: () => {
					this.isLoadingData = false;
				},
				fail: () => {
					this.isLoadingData = false;
				},
				id: this.$route.params.inspectionId,
			});
		},
		validateHeader() {
			let result = true;
			if (Array.isArray(this.inspection?.headers)) {
				this.inspection.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		validateGroup(index) {
			let result = true;
			if (Array.isArray(this.inspection?.bodies)) {
				this.inspection.bodies[index].items.forEach((body) => {
					if (
						!body.value ||
						body.value === undefined ||
						body.value == '' ||
						(body.value &&
							body.value === 'NO' &&
							(!body.observations || body.observations == ''))
					)
						result = false;
				});
			}
			return result;
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				}
			}
			return found || null;
		},
		groupData(status) {
			// INITIAL DATA
			const service_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'service',
				propFound: 'value',
			});
			const date = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'date',
				propFound: 'value',
			});
			const manager_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'manager',
				propFound: 'value',
			});
			const management_id = this.findValueInArray({
				items: this.costCenters,
				prop: 'id',
				val: service_id,
				propFound: 'management_id',
			});

			let dataSend = {
				register: {
					inspection_id: this.inspection.id,
					format: this.inspection.format,
					service_id,
					status,
					date,
					manager_id,
					management_id,
					company_id: parseInt(localStorage.getItem('company_id')),
				},
				headers: [],
				bodies: [],
			};

			// SET HEADERS
			dataSend.headers = this.inspection.headers.map(function (header) {
				return { header_id: header.id, value: (header?.value || '').toString() };
			});

			// SET BODIES
			this.inspection.bodies.forEach((body) => {
				body.items.forEach((item) => {
					dataSend.bodies.push({
						compliance: item.value,
						observations: item?.observations || null,
						body_id: item.id,
					});
				});
			});
			return dataSend;
		},
		send(status = 'PENDING') {
			if (status == 'PENDING') {
				this.isLoadingSave = true;
			} else {
				this.isLoadingDraft = true;
			}

			this.create({
				success: () => {
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					this.$router.push(
						'/dashboard/inspections/' + this.inspection.format + '/registers'
					);
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				},
				fail: (error) => {
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					const { response } = error;
					if (response && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					}
					$EventBus.$emit(
						'showSnack',
						'error',
						'Algo salio mal, no se guardo la información.'
					);
				},
				data: this.groupData(status),
			});
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		getLabel(value) {
			let newLabel = '';
			if (value.toLowerCase() == 'si') {
				newLabel = 'Cumplimiento';
			} else if (value.toLowerCase() == 'no') {
				newLabel = 'Incumplimiento';
			} else if (value.toLowerCase() == 'na') {
				newLabel = 'No Aplica';
			}
			return newLabel;
		},
		getPercentCompliance() {
			let percent = 0;
			let itemsYes = 0;
			this.optionsAnswer.forEach((option) => {
				if (option.value.toLowerCase() == 'si') {
					itemsYes = option.total;
				}
			});
			if (this.totalItemsApply > 0) {
				percent = parseFloat(
					parseFloat(
						((100 * itemsYes) / this.totalItemsApply).toString()
					).toFixed(0)
				);
			}
			return percent;
		},
		filterService(item, queryText) {
			return (
				item.pep_code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
					-1 ||
				item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
			);
		},
		isDisabled() {
			let result = true;
			if (
				this.$route.name == 'InspectionsRegisterShow' &&
				this.inspection?.status == 'PENDING'
			) {
				result = false;
			}
			return result;
		},
	},
	destroyed() {
		this.cleanInspection();
	},
	components: { FormHeader, FormBody, ValidationAlert },
};
